<template>
  <div class="wrapper">
    <div ref="file" :class="[store.getTheme()]" class="card"  >
    <router-link
      class="white-text"
      :to="{ name: 'Dossier', params: { search: file.searchQuery, title: file.title, detail: file.detail }}"
      >
      <div class="card-image">
        <progressive-img v-show="file.illustrationArticleId" customClass="largeImage" imageCustomClass="image-custom-class"
          :src="`${shared.getImagesUrl()}/${file.illustrationArticleId}?size=large`"
          :placeholder="`${shared.getImagesUrl()}/${file.illustrationArticleId}?size=thumbnail`"
          fallback="/img/MahanaTahitiNEWS.svg"
        />
        <progressive-img v-show="!file.illustrationArticleId" customClass="largeImage" imageCustomClass="image-custom-class"
          src="/img/loading.svg"
        />
        <!-- <progressive-img v-else
          fallback="/img/MahanaTahitiNEWS.svg"
        /> -->
        <span class="card-title">{{file.title}}</span>
      </div>
      <div :id="'card'+file.id" style="z-index:100" class="card-content" :class="[store.getTheme()]">
        <div :class="[store.getTheme()]" class="divider"></div>
        <i>{{file.category}}</i><br><br>
        {{file.detail}}<br><br>
      </div>
    </router-link>
      <!-- <div class="card-action">
        <a v-if="file.id" class='dropdown-trigger waves-effect waves-light btn-flat btn-large' :class="[store.getTheme()]" :data-target="'dropdown-share'+file.id" :id="'dropdown-trigger'+file.id" @click.stop="$emit('reading')">
          <font-awesome-icon class="valign" :icon="['fas', 'share-alt']" size="lg" />
        </a>
      </div> -->
      <!-- <ul v-if="file.id" :id="'dropdown-share'+file.id" :class="[store.getTheme()]" class='dropdown-content'>
        <li><div>
          <a class="waves-effect col s12 waves-light btn facebook" @click="shared.mesure('share', 'Facebook', shareLink, article.id, store.getUserToken())" :href="`http://www.facebook.com/sharer.php?display=page&u=${shareLink}`" target="_blank">
          <font-awesome-icon class="valign" :icon="['fab', 'facebook-f']" size="lg" /></a>
        </div></li>
        <li><div class="row">
          <a rel="noopener" class="waves-effect col s12 waves-light btn twitter" @click="shared.mesure('share', 'Twitter', shareLink, article.id, store.getUserToken())" :href="`https://twitter.com/share?url=${shareShortLink}&amp;text=${article.title}&amp;hashtags=mahanatahiti`" target="_blank">
          <font-awesome-icon class="valign" :icon="['fab', 'twitter']" size="lg" /></a>
        </div></li>
        <li><div class="row">
          <a rel="noopener" class="waves-effect col s12 waves-light btn linkedin" @click="shared.mesure('share', 'LinkedIn', shareLink, article.id, store.getUserToken())" :href="`http://www.linkedin.com/shareArticle?mini=true&amp;url=${shareLink}`" target="_blank">
          <font-awesome-icon class="valign" :icon="['fab', 'linkedin']" size="lg" /></a>
        </div></li>
        <li><div class="row">
          <a rel="noopener" class="waves-effect col s12 waves-light btn whatsapp" @click="shared.mesure('share', 'WhatsApp', shareLink, article.id, store.getUserToken())" :href="`https://wa.me/?text=Voici un article de ${article.source} que j'ai trouvé avec Mahana Tahiti : ${article.title} ${shareLink}`" target="_blank">
          <font-awesome-icon class="valign" :icon="['fab', 'whatsapp']" size="lg" /></a>
        </div></li>
        <li><div class="row">
          <a rel="noopener" class="waves-effect col s12 waves-light btn windows" @click="shared.mesure('share', 'Email', shareLink, article.id, store.getUserToken())" :href="`mailto:?Subject=${article.title}&amp;Body=Voici un article de ${article.source} que j'ai trouvé avec Mahana Tahiti : ${shareLink}`" >
          <font-awesome-icon class="valign" :icon="['fas', 'envelope']" size="lg" /></a>
        </div></li>
        <li><div class="row">
          <a :href="articleDirectLink"
          class="waves-effect col s12 waves-light btn tumblr" @click.prevent="copyArticleDirectLink()" >
          <font-awesome-icon class="valign" :icon="['fas', 'clipboard']" size="lg" /></a>
        </div>
        </li>
        <li v-if="navigatorShare"><div class="row">
          <a rel="noopener" class="waves-effect col s12 waves-light btn google" @click="shared.mesure('share', 'Smartphone', shareLink, article.id, store.getUserToken()); webshare(article.title, `Voici un article de ${article.source} que j'ai trouvé avec Mahana Tahiti`, shareLink)" >
          <font-awesome-icon class="valign" :icon="['fas', 'mobile-alt']" size="lg" /></a>
        </div></li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import shared from '../shared'
import { store } from '../store'

export default {
  name: 'File',
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      shared: shared,
      store: store
      // navigatorShare: navigator.share
    }
  },
  computed: {
    // slug () {
    //   // console.log(shared.stringToSlug(this.article.title))
    //   return shared.stringToSlug(this.file.title)
    // },
    // articleDirectLink () {
    //   return `${shared.getHostUrl()}/article/${this.article.id}/${this.slug}`
    // },
    // shareLink () {
    //   return `${shared.getHostUrl()}/share/article/${this.article.id}/${this.slug}`
    // },
    // shareShortLink () {
    //   return `${shared.getHostUrl()}/share/article/${this.article.id}/1`
    // }
  },
  methods: {
    // copyArticleDirectLink () {
    //   navigator.clipboard.writeText(this.articleDirectLink).then(() => {
    //     /* clipboard successfully set */
    //     console.log('ok to clipboard')
    //   }, () => {
    //     /* clipboard write failed */
    //     console.log('cannot copy to clipboard')
    //   })
    // },
    // webshare (title, text, shareLink) {
    //   if (navigator.share) {
    //     navigator.share({
    //       title: title,
    //       text: text,
    //       url: shareLink
    //     })
    //   }
    // }
  }
}

</script>
<style scoped>
.card {
  margin: 0 0 0 0!important;
  height:400px;
  cursor: auto;
  border:solid;
  border-width: 1px;
  border-radius: 10px!important;
  margin-top:6px;
  margin-bottom:6px;
}
.zoomin {
  cursor: zoom-in;
}
.card .card-action {
  padding: 0 0 0 0;
}
.card-title {
  text-shadow: 5px 2px 4px #000,
                5px 2px 10px #000;
  color:white;
  padding:5px !important;
  text-align: center;
  font-family: 'Roboto Condensed';
  font-weight: 700!important;
  right:0px;
  z-index: 2;
}
.card-content {
  font-family: 'Roboto Condensed', sans-serif;
  max-height: 10px;
  padding:10px!important
}

.card-image {
  max-height: 290px;
  width: 100%;
  display:block;
}

.divider {
  margin-bottom: 10px;
  background-color: rgb(143, 143, 143);
}
.card-image:before {
  position: absolute;
  content:"";
  height:81%;
  width:100%;
  top:20%;
  left:0;
  z-index: 1;
}
.btn {
  height: 43px;
}
.dropdown-content {
  /* transform-origin: 100% 100%!important; */
  bottom:55px;
  min-width: 50px;
  top:auto!important;
  overflow: hidden;
  border-style: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.row {
  margin:0;
}
.row .col {
  padding:0px
}

.dropdown-content li {
  min-height: auto;
}
.no-event {
  pointer-events: none
}
.content {
  font-size: 16px!important;
  max-height: 90px;
  text-align: justify;
  overflow-wrap: break-word;
}
.largeImage {
  max-height: 290px;
  /* height:450px; */
  /* object-fit: cover;
  -o-object-fit: cover;
  object-position:bottom; */
  /* max-width: 700px!important; */
  /* width: 100%!important; */
  width: 100%;
  z-index:0;
}

</style>
